<template>
	<div style="font-size=4rem">
		<h3 class="sub-title">{{ org.name }}</h3>
		<!--class="form-horizontal"-->
		<form-filter>
			<period v-model="period" />
			<form-item>
				<button class="btn btn-primary" @click="getData">查詢</button>
			</form-item>
		</form-filter>
	</div>
</template>

<script>
import axios from "axios";
import { file_resolve } from "@/api/base.js";
import period from "@/components/period.vue";
import formFilter from "@/components/formFilter.vue";
import formItem from "@/components/formItem.vue";

export default {
	props: {
		org: {
			type: Object,
			default: () => {}
		},
		url: {
			type: String,
			default: ""
		}
	},
	components: {
		period,
		formFilter,
		formItem
	},
	data() {
		const today = new Date();
		const date = today.toISOString().substr(0, 10);
		return {
			period: {
				begin: date,
				end: date
			}
		};
	},
	methods: {
		getData() {
			let rdate = /^\d{4,4}-\d{2,2}-\d{2,2}$/;
			if (!rdate.test(this.period.begin)) {
				this.$root.$message.open({
					status: 400,
					message: "開始日期格式錯誤"
				});
				return -1;
			}
			if (!rdate.test(this.period.end)) {
				this.$root.$message.open({
					status: 400,
					message: "結束日期格式錯誤"
				});
				return -1;
			}
			let params = {};
			if (!(this.org.id == 0)) {
				params = Object.assign(params, {
					org_id: this.org.id
				});
			}
			params = {
				...params,
				...{
					begin_time: this.period["begin"],
					end_time: this.period["end"]
				}
			};
			axios
				.post(
					this.url,
					{
						...params
					},
					{ responseType: "blob" }
				)
				.then(file_resolve("EBookStatusEventLog.xlsx"))
				.catch(res => {
					console.log(JSON.stringify(res));
					// this.$errorHandler({ error: res });
				});
		}
	}
};
</script>

<style lang="scss" scoped>
.btn {
	&.btn-manage {
		margin-top: 0.7rem;
	}
}
</style>
